import './App.css';

function App() {
  return (
          <div class="input-group mb-3">
          <input type="text" class="form-control" placeholder="TENRATS SEARCH" aria-label="TENRATS SEARCH" aria-describedby="button-addon2" />
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" id="button-addon2">SEARCH</button>
          </div>
        </div>
  );
}

export default App;
